<template>
  <div>
    <h3 class="page-title">ۋاكالەتچى باشقۇرۇش</h3>
    <el-divider></el-divider>
    <!-- table-box -->
    <div v-if="showList">
      <el-row class="rtl-right">
        <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add">قوشۇش </el-button>
      </el-row>

      <div class="table-box" v-if="pageTags.list">
        <el-table
          :data="tableData" 
          border 
          style="width: 100%" 
          v-loading="loading">
          <!-- <el-table-column type="expand">
            <template slot-scope="props">
              <el-divider>مۇددەت باھا</el-divider>
              <el-table :data="props.row.prices" border style="width: 100%">
                <el-table-column prop="term.name_ug" label="مۇددەت"></el-table-column>
                <el-table-column prop="term.days" label="كۈن"></el-table-column>
                <el-table-column prop="price" label="باھا"></el-table-column>
              </el-table>
              <el-divider>سوۋغات</el-divider>
              <el-table :data="props.row.gifts" border style="width: 100%">
                <el-table-column prop="agent_giftable_type" width="120" label="تۈرى">
                  <template slot-scope="scope">
                    <label v-show="scope.row.agent_giftable_type=='courses'">دەرس</label>
                    <label v-show="scope.row.agent_giftable_type=='teachers'">ئوقۇتقۇچى</label>
                  </template>
                </el-table-column>
                <el-table-column prop="agentGiftable.name_ug" label="ئىسمى"></el-table-column>
                <el-table-column prop="term.name_ug" label="مۇددەت"></el-table-column>
              </el-table>
              <el-divider>ئىزاھات</el-divider>
              <p class="mytitle mytitle-ug">ئىزاھات  (ئۇيغۇرچە):</p>
              <div v-html="props.row.description_ug" class="mycontent mycontent-ug"></div>
              <el-divider></el-divider>
              <p class="mytitle mytitle-zh">描述（中文）:</p>
              <div v-html="props.row.description_zh" class="mycontent"></div>
            </template>
          </el-table-column> -->
          <el-table-column prop="name_ug" label="ئىسمى (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label="ئىسمى (خەنزۇچە)"></el-table-column>
          <el-table-column prop="level" label="دەرىجىسى" width="80"></el-table-column>
          <el-table-column prop="prices[0].price" label="باھاسى" width="80"></el-table-column>
          <el-table-column prop="prices[0].term.name_ug" label="مۇددەت" width="80"></el-table-column>
          <el-table-column prop="agent_percent" label="ۋاكالەتچى پىرسەنتى" width="130"></el-table-column>
          <el-table-column prop="course_percent" label="دەرس پىرسەنتى" width="100"></el-table-column>
          <el-table-column prop="vip_percent" label="vip پىرسەنتى" width="100"></el-table-column>
          <el-table-column prop="reward_percent" label="مۇكاپات پىرسەنتى" width="120"></el-table-column>
          <el-table-column prop="created_at" label="كىرگەن ۋاقىت"></el-table-column>
          <el-table-column prop="status" width="80" label="ھالەت">
            <template slot-scope="scope">
              <label v-show="scope.row.status==1">ئوچۇق</label>
              <label v-show="scope.row.status==0">ئېتىك</label>
            </template>
          </el-table-column>
          <el-table-column prop="order" label="تەرتىپى" width="80"></el-table-column>
          <el-table-column label="مەشغۇلات" prop="dosome" width="200">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleEdit(scope.row)">تەھرىرلەش</el-button>
              <!-- <el-button size="mini" type="warning" @click="handleDetail(scope.row)">تەپسىلاتى</el-button> -->
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">ئۆچۈرۈش </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :current-page="current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="total">
        </el-pagination>  -->
      </div>
    </div>

    <!-- qrcode -->
    <div v-if="!showList">
      <el-row class="rtl-right">
        <el-button type="primary" plain icon="el-icon-right" @click="backToList">قايتىش</el-button>
      </el-row>
      <el-divider>{{curItem.name_ug}}</el-divider>
      <div v-loading="qrcodeLoading">
        <el-row :gutter="5">
          <el-col :span="12">
            <p class="detail-title">ئۈستىدىكى ۋاكالەتچى</p>
            <p v-for="(item,index) in agentData.agentParant" :key="index" class="name-text">{{item.name_ug}}</p>
          </el-col>
          <el-col :span="12">
            <p class="detail-title">ئەگەشكۈچىلىرى</p>
            <p v-for="(item,index) in agentData.agentFans" :key="index" class="name-text">{{item.name_ug}}</p>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- el-dialog -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="65%"
      top="5vh"
      :before-close="handleClose">
      <div class="rtl-right form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="ئىسمى (ئۇيغۇرچە)" prop="name_ug">
            <el-input v-model="ruleForm.name_ug" placeholder="ئىسمى (ئۇيغۇرچە)"></el-input>
          </el-form-item>
          <el-form-item label="ئىسمى (خەنزۇچە)" prop="name_zh">
            <el-input v-model="ruleForm.name_zh" class="ltr-left" placeholder="类型（中文）"></el-input>
          </el-form-item>
          <el-form-item label="دەرىجىسى" prop="level">
            <el-input v-model="ruleForm.level" type="number" class="ltr-left" placeholder="دەرىجىسى"></el-input>
          </el-form-item>
          <el-form-item label="ۋاكالەتچى پىرسەنتى" prop="agent_percent" class="long-label">
            <el-input v-model="ruleForm.agent_percent" type="number" class="ltr-left" placeholder="ۋاكالەتچى پىرسەنتى"></el-input>
          </el-form-item>
          <el-form-item label="دەرس پىرسەنتى" prop="course_percent" class="long-label">
            <el-input v-model="ruleForm.course_percent" type="number" class="ltr-left" placeholder="دەرس پىرسەنتى"></el-input>
          </el-form-item>
          <el-form-item label="ئالاھىدە ئەزا پىرسەنتى" prop="vip_percent" class="long-label">
            <el-input v-model="ruleForm.vip_percent" type="number" class="ltr-left" placeholder="ئالاھىدە ئەزا پىرسەنتى"></el-input>
          </el-form-item>
          <el-form-item label="مۇكاپات پىرسەنتى" prop="reward_percent" class="long-label">
            <el-input v-model="ruleForm.reward_percent" type="number" class="ltr-left" placeholder="مۇكاپات پىرسەنتى"></el-input>
          </el-form-item>
          <!-- <el-form-item label="ئىزاھات (ئۇيغۇرچە)" prop="description_ug" class="long-label">
            <el-input type="textarea" v-model="ruleForm.description_ug" placeholder="ئىزاھات (ئۇيغۇرچە)"></el-input>
          </el-form-item>
          <el-form-item label="ئىزاھات (خەنزۇچە)" prop="description_zh" class="long-label">
            <el-input type="textarea" v-model="ruleForm.description_zh" class="ltr-left" placeholder="描述（中文）"></el-input>
          </el-form-item> -->

          <!-- term-price -->
          <div class="main-box">
            <el-form-item label="مۇددەت باھاسى" prop="price">
              <el-row :gutter="15" v-for="(one,index) in termPriceList" :key="index" class="price-box">
                <el-col :span="10">
                  <el-select v-model="one.term_id" class="rtl-right" filterable placeholder="مۇددەت  تاللاڭ ">
                    <el-option
                      v-for="item in termsData"
                      :key="item.id"
                      :label="item.name_ug + '---'+ item.days + ' كۈن  ' "
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="10">
                  <el-input type="number" v-model="one.price" class="ltr-left" placeholder="باھاسى"></el-input>
                </el-col>
                <el-col :span="2" class="last-col" v-if="isMultiTermPrice">
                  <i class="el-icon-error" @click="toRemoveTermPrice(one,termPriceList,index)"></i>
                </el-col>
              </el-row>
            </el-form-item>
            <el-row v-if="isMultiTermPrice">
              <el-col :span="10" :offset="7">
                <el-button icon="el-icon-circle-plus-outline" class="add-icon" @click="addItem(termPriceList)">يەنە بىرنى قوشۇش</el-button>
              </el-col>
            </el-row>
          </div>

          <!-- gift(course) -->
          <div class="main-box">
            <el-form-item label="سوۋغات">
              <el-row :gutter="10" v-for="(one,index) in giftsList" :key="index" class="price-box">
                <el-col :span="6">
                  <el-select v-model="one.type" class="rtl-right" clearable placeholder="تۈرى">
                    <el-option
                      v-for="item in ownerableTypeList"
                      :key="item.name"
                      :label="item.name"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="10" v-if="one.type == 'courses'">
                  <p class="el-input__inner" @click="toChoiseCourse(index)">
                    <label v-if="one.name!=''">{{one.name}}</label>
                    <label v-if="one.name==''" style="color: #C0C4CC;font-size: 14px;">دەرس تاللاش</label>
                  </p>
                </el-col>
                <el-col :span="10" v-if="one.type == 'teachers'">
                  <el-select v-model="one.id" class="rtl-right" filterable clearable placeholder="ئوقۇتقۇچى">
                    <el-option
                      v-for="item in teachersData"
                      :key="item.id"
                      :label="item.name_ug"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-col>
                <!-- <el-col :span="6">
                  <el-select v-model="one.term_id" class="rtl-right" filterable placeholder="مۇددەت  تاللاڭ ">
                    <el-option
                      v-for="item in termsData"
                      :key="item.id"
                      :label="item.name_ug + '---'+ item.days + ' كۈن  ' "
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-col> -->
                <el-col :span="2" class="last-col">
                  <i class="el-icon-error" @click="removeItem(giftsList,index)"></i>
                </el-col>
              </el-row>
            </el-form-item>
            <el-row>
              <el-col :span="10" :offset="7">
                <el-button icon="el-icon-circle-plus-outline" class="add-icon" @click="addItemGift(giftsList)">يەنە بىرنى قوشۇش</el-button>
              </el-col>
            </el-row>
          </div>
          
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="تەرتىپى" prop="order">
                <el-input v-model="ruleForm.order" type="number" class="ltr-left" placeholder="تەرتىپى"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="ئەگەشكۈچىلىرى" prop="fans" class="radio-form">
                <el-radio v-model="ruleForm.fans" label="1">بار</el-radio>
                <el-radio v-model="ruleForm.fans" label="0">يوق</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="ھالەت" prop="status" class="radio-form">
                <el-radio v-model="ruleForm.status" label="1">ئوچۇق</el-radio>
                <el-radio v-model="ruleForm.status" label="0">ئېتىك</el-radio>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

    <!-- course -->
    <el-drawer
      title="دەرس  تاللاش"
      size="50%"
      :visible.sync="courseDrawer"
      direction="rtl">
      <div class="drawer-table-box">
        <el-table :data="courseTableData" @selection-change="handleSelectionChange" border style="width: 100%">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="name_ug" label="ئىسمى"></el-table-column>
          <el-table-column prop="teacher.name_ug" label="ئوقۇتقۇچى"></el-table-column>
          <!-- <el-table-column label="مەشغۇلات" prop="dosome" width="300">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="confirmChoiseCourse(scope.row)">تاللاش</el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <el-pagination
          @size-change="handleCourseSizeChange"
          @current-change="handleCourseCurrentChange"
          background
          :current-page="course_current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="course_total">
        </el-pagination>
        <div style="text-align: right;margin-right: 15px;">
          <el-button type="primary" @click="confirmChoiseCourse()">تاللاشنى مۇقىملاش</el-button>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<style lang="scss" scoped>
  .mytitle{
    color: #909399;
    font-size: 14px;
    padding-bottom: 10px;
  }
  .mycontent{
    direction: initial;
    text-align: initial;
    text-align: justify;
    line-height: 1.5rem;
  }
  .mytitle-ug,.mycontent-ug{
    direction: rtl;
    text-align: right;
  }
  .mytitle-zh{
    direction: ltr;
    text-align: left;
  }
  .detail-title{
    direction: rtl;
    padding: 10px 0;
  }
  .name-text{
    direction: rtl;
  }
  .last-col{
    text-align: center;
    position: relative;
    top: 8px;
    .el-icon-error{
      color: #ff5151;
      font-size: 28px;
      cursor: pointer;
    }
  }
  .price-box{
    overflow: hidden;
    [class*="el-col-"] {
      float: right;
    }
  }
  .add-icon{
    width: 100%; 
  }
  .main-box{
    border: 1px dotted #67C23A;
    margin-top: 10px;
    padding: 10px 0;
    border-radius: 5px;
  }
  div.el-divider--horizontal {
    margin: 20px 0;
  }
</style>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
      self.getTermsList();
      self.getCoursesList();
      self.getTeachersList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        per_page:10,
        current_page:1,
        last_page:0,
        total:0,
        pageTags:{},
        curItem:{},
        showList:true,
        qrcodeLoading:false,
        agentData:{},
        dialogVisible:false,
        ruleForm: {
          name_ug: "",
          name_zh: "",
          level:"",
          agent_percent:"",
          course_percent:"",
          vip_percent:"",
          reward_percent:"",
          fans:"1",
          description_ug:"",
          description_zh:"",
          order:"",
          status: "1"
        },
        dialogTitle:"",
        addDialog:true,
        editId:"",
        termsData:[],
        termPriceList:[
          {
            id:null,
            term_id:"",
            price:""
          }
        ],
        ownerableTypeList:[
          {
            name:"دەرس",
            value:"courses"
          },
          {
            name:"ئوقۇتقۇچى",
            value:"teachers"
          }
        ],
        giftsList:[
          // {
          //   type:"",
          //   id:"",
          //   name:"",
          // }
        ],
        courseDrawer:false,
        courseTableData:[],
        course_total:0,
        course_current_page:1,
        course_last_page:0,
        course_per_page:10,
        isMultiTermPrice:false,
        teacherDrawer:false,
        teachersData:[],
        multipleSelection:[],
        curIndex:""
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/agent",{
          // page:self.current_page,
          // per_page:self.per_page
        }).then(response => {
          if (response.status == 200) {
            self.tableData = response.data.data;
            // self.total = response.data.meta.total;
            // self.current_page = response.data.meta.current_page;
            // self.last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      // handleSizeChange(val) {
      //   self.per_page = val;
      //   self.getList();
      // },
      // handleCurrentChange(val) {
      //   self.current_page = val;
      //   self.getList();
      // },
      getTermsList(){
        self.$fetch("admin/term").then(response => {
          if (response.status == 200) {
           self.termsData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getCoursesList(){
        self.$fetch("admin/course",{
          page:self.course_current_page,
          per_page:self.course_per_page
        }).then(response => {
          if (response.status == 200) {
           self.courseTableData = response.data.data;
           self.course_total = response.data.meta.total;
           self.course_current_page = response.data.meta.current_page;
           self.course_last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      handleCourseSizeChange(val) {
        self.course_per_page = val;
        self.getCoursesList();
      },
      handleCourseCurrentChange(val) {
        self.course_current_page = val;
        self.getCoursesList();
      },
      handleDetail(row){
        self.curItem = row;
        self.showList = false;

        self.qrcodeLoading = true;
        self.$fetch("admin/agent/"+self.curItem.id).then(response => {
          if (response.status == 200) {
            self.agentData = response.data.data;
          } else{
            console.log(response.message);
          }
          self.qrcodeLoading = false;
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.qrcodeLoading = false;
        });
      },
      handleDelete(index, row) {
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/agent/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      getTeachersList(){
        self.$fetch("admin/teacher").then(response => {
          if (response.status == 200) {
           self.teachersData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      add() {
        self.addDialog = true;
        self.dialogTitle = "قوشۇش";
        self.dialogVisible = true;
      },
      handleEdit(row) {
        self.addDialog = false;
        self.dialogTitle = "تەھرىرلەش";
        self.editId = row.id;
        self.ruleForm.name_ug = row.name_ug;
        self.ruleForm.name_zh = row.name_zh;
        self.ruleForm.level = row.level;
        self.ruleForm.agent_percent = row.agent_percent;
        self.ruleForm.course_percent = row.course_percent;
        self.ruleForm.vip_percent = row.vip_percent;
        self.ruleForm.reward_percent = row.reward_percent;
        self.ruleForm.fans = row.fans + '';
        self.ruleForm.description_ug = row.description_ug;
        self.ruleForm.description_zh = row.description_zh;
        if(row.prices.length>0){
          var tmpArr = [];
          row.prices.forEach(function(one,index){
            var tmpObj = {};
            tmpObj.id = one.id;
            tmpObj.term_id = one.term_id;
            tmpObj.price = one.price;
            tmpArr.push(tmpObj);
          });
          self.termPriceList = tmpArr;
        }

        if(row.gifts.length>0){
          var tmpArr1 = [];
          row.gifts.forEach(function(one,index){
            var tmpObj1 = {};
            tmpObj1.type = one.agent_giftable_type;
            if(one.agentGiftable){
              tmpObj1.id = one.agentGiftable.id;
              tmpObj1.name = one.agentGiftable.name_ug;
            }
            // tmpObj1.term_id = one.term.id;
            tmpArr1.push(tmpObj1);
          });
          self.giftsList = tmpArr1;
        }
        
        self.ruleForm.status = row.status + '';
        self.ruleForm.order = row.order;
        self.dialogVisible = true;
      },
      submitForm(formName) {
        var myData = {
          name_ug: self.ruleForm.name_ug,
          name_zh: self.ruleForm.name_zh,
          level: self.ruleForm.level,
          agent_percent: self.ruleForm.agent_percent,
          course_percent: self.ruleForm.course_percent,
          vip_percent: self.ruleForm.vip_percent,
          reward_percent: self.ruleForm.reward_percent,
          fans: self.ruleForm.fans,
          description_ug: self.ruleForm.description_ug,
          description_zh: self.ruleForm.description_zh,
          prices: self.termPriceList,
          gifts: self.giftsList,
          status: self.ruleForm.status,
          order: self.ruleForm.order
        }

        if(self.addDialog){
          self.$post('admin/agent',myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response.data.message);
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }else{
          self.$put('admin/agent/'+self.editId,myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500
              });
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm = {
          name_ug: "",
          name_zh: "",
          level:"",
          agent_percent:"",
          course_percent:"",
          vip_percent:"",
          reward_percent:"",
          fans:"1",
          description_ug:"",
          description_zh:"",
          status: "1",
          order: ""
        }
        self.termPriceList = [
          {
            id:"",
            term_id:"",
            price:""
          }
        ]
        self.giftsList = []
      },
      closeDialog(formName) {
        self.resetForm(formName);
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      },
      addItem(arr){
        if(arr.length < 30){
          var tmpData = {
            term_id:'',
            price:''
          };
          arr.push(tmpData);
        }else{
          self.$message({
            message: 'بەك كۆپ بوپكەتتىمۇ قانداق؟',
            type: "warning"
          });
        }
      },
      addItemGift(arr){
        if(arr.length < 30){
          var tmpData = {
            type:'',
            id:'',
            name:'',
            // term_id:''
          };
          arr.push(tmpData);
        }else{
          self.$message({
            message: 'بەك كۆپ بوپكەتتىمۇ قانداق؟',
            type: "warning"
          });
        }
      },
      toChoiseCourse(index){
        self.curIndex = index;
        self.courseDrawer = true;
      },
      confirmChoiseCourse(){
        self.removeItem(self.giftsList,self.curIndex);

        self.multipleSelection.forEach(function(item,index){
          var tmpCourseObj = {};
          tmpCourseObj.id = item.id;
          tmpCourseObj.name = item.name_ug;
          tmpCourseObj.type = 'courses';
          self.giftsList.push(tmpCourseObj);
        });
        self.courseDrawer = false;
      },
      handleSelectionChange(val) {
        self.multipleSelection = val;
      },
      toRemoveTermPrice(one,arr,index){
        console.log(one);
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/price/'+one.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.removeItem(arr,index);
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      removeItem(arr,index){
        arr.splice(index, 1);
      },
      backToList(){
        self.curItem = {};
        self.showList = true;
      }
    },
    deactivated(){
      self.backToList();
    }
  };
</script>
